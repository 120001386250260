@import 'styles/colors.module.scss';
@import 'styles/variables.module.scss';
@import 'styles/fontfaces';
@import 'styles/variables.scss';

body {
  font-family: $DEFAULT_FONT;
  font-weight: $REGULAR;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
  padding: 0 !important;
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-11 {
  font-size: 11px;
}

.small-font-12 {
  font-size: 12px;
}

.elements-spacing {
  margin: 15px;
  color: $on-general;
  .MuiTableCell-body {
    font-weight: 400;
  }
}
.elements-spacing-small {
  margin-left: 10px;
}
.elements-spacing-title-small {
  margin-top: 10px;
  margin-left: 10px;
}
.small-font-14 {
  font-size: 14px;
}
.color-default {
  color: $DEFAULT_FONT_COLOR;
}

.font-18 {
  font-size: 18px;
}

.M-10 {
  margin: 10px;
}
.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.height-fit {
  height: fit-content;
}
.MTB-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.MR-10 {
  margin-right: 10px !important;
}
.MR-16 {
  margin-right: 16px;
}

.ML-10 {
  margin-left: 10px;
}
.ML-5 {
  margin-left: 5px;
}
.MTB-10 {
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-justify-center {
  @extend .flex;
  justify-content: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-row-3 {
  flex-basis: 30%;
}

.input-text {
  background-color: white;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font;
  font-weight: $BOLD !important;
}

.italic {
  font-style: italic;
}

.black {
  @extend .default-font;
  font-weight: $BLACK;
}

.unselected {
  color: $DISABLED !important;
}

.selected {
  color: $DEFAULT_TEXT !important;
}

.backdrop-color {
  background-color: $BACKGROUNDS;
}

.backdrop-transparent {
  background-color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 4 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $graph-first-color !important;
}

.proposta {
  @extend .color-white;
  background-color: $PROPOSTA !important;
}

.compromesso {
  @extend .color-white;
  background-color: $COMPROMESSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $graph-sixth-color !important;
}

.opzionato {
  @extend .color-white;
  background-color: $OPZIONATO !important;
}

.richiesto {
  @extend .color-white;
  background-color: $RICHIESTO !important;
}
.cancellato {
  @extend .color-white;
  background-color: $deleted !important;
}
.riservato {
  @extend .color-white;
  background-color: $RISERVATO !important;
}
.scaduto {
  @extend .color-white;
  background-color: $expired !important;
}
.in_corso {
  @extend .color-white;
  background-color: $IN_CORSO !important;
}
.confermata {
  @extend .color-white;
  background-color: $CONFERMATO !important;
}
.non_confermata {
  @extend .color-white;
  background-color: $NON_CONFERMATO !important;
}
.controfirmata {
  @extend .color-white;
  background-color: $CONTROFIRMATO !important;
}
.validata {
  @extend .color-white;
  background-color: $VALIDATO !important;
}
.search-button-old {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button-old;
}

.add-button {
  @extend .search-button-old;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}
.border-point {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-right: 5px;
}
.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.event-store-background {
  background-color: $EVENT_STORE_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-video-background {
  background-color: $EVENT_VIDEO_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-call-background {
  background-color: $EVENT_CALL_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-width {
  width: 95% !important;
  margin: 5px;
}
.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  width: 500px; //34.72%
  height: 300px;
  background-color: white !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.modal-custom-otp-container {
  height: 430px !important;
  width: 540px !important;
  max-width: 540px !important;
}

.otp-modal-text {
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin: 0 10px;
  font-weight: 400;
}

.otp-modal-title {
  padding-bottom: 0px !important;
  padding-top: 32px !important;
}

.bulk-upload-modal {
  height: 300px;
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: white;
  color: $on-general;
  padding: 0px !important;
}
.custom-listbox:hover {
  background-color: white;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.fixedPosition {
  position: fixed;
  z-index: 1201 !important;
}

.absolute-position-relative-content-loader {
  position: fixed;
  top: calc(50% - 20px);
  height: 40px !important;
  animation: none;
  left: calc(50% - 20px);
}

.absolute-position-relative-content-loader > svg {
  top: calc(50% - 20px);
  animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite;
  position: relative;
}

.relative {
  position: relative;
}

.snackbar-body {
  color: $accent;
  font-weight: 400;
  background-color: #f8f8f8;
  font-family: 'Lato';
  text-align: center;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-amber-body {
  color: #fdc132;
  font-weight: 400;
  background-color: #f8f8f8;
  font-family: 'Lato';
  text-align: center;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-body-error {
  color: #f35959;
  font-weight: 400;
  background-color: #f8f8f8;
  font-family: 'Lato';
  text-align: center;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputAdditional {
  padding: 0 10px;
}

.ellipsis {
  text-overflow: ellipsis;
  // margin-bottom: 7px;
}

.justify-center {
  justify-content: center;
}

.link-on-hover {
  transition: 0.5s;
  &:hover {
    color: $EDITING_COLOR !important;
  }
}

.privacy-divider {
  margin-top: 20px;
}

.additional-divider {
  margin-top: 20px;
  margin-bottom: 10px;
}

.button-right {
  margin-left: 95%;
}
.align-right {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px !important;
  margin-left: auto !important;
  display: flex !important;
  height: 50px !important;
  width: 50px !important;
}

.new_input {
  margin: 15px !important;
}

// .MuiFormHelperText-root.Mui-error {
//   margin-top: 20px;
// }

.button-findPdf {
  @extend .search-button-old;
  margin: 5px 10px;
}
.button-findPdf-menu {
  @extend .search-button-old;
  margin-right: 10px !important;
}
// .menu-item {
//   margin-top: 10px;
// }
// .menu-item-dropdown {
//   min-width: 200px;
// }
.pdfTitle {
  display: contents;
}
.upload-button {
  margin-right: 10px !important;
}
.error-import-modal-title {
  text-align: left;
  margin: 10px 0 !important;
  width: 100%;
  font-size: 16px;
}
.download-button {
  margin-right: 10px !important;
}

.loader-pdf-proposal {
  background-color: transparent !important;
  margin: 5px 10px;
  position: relative;
  height: 40px;
  width: 40px;
}

.expired-date-input {
  width: 125px;
  margin-bottom: 0px !important;
  .MuiInput-input {
    text-align: center !important;
  }
}

.main-container.no-shadow-4-screenshot * {
  box-shadow: none;
}

.text-align-left {
  text-align: left;
}
.line-height-initial {
  line-height: initial;
}
.page-header-actions {
  position: relative;
  bottom: 55px;
  &.calendar-detail {
    right: -14px;
  }
  &.account-manager,
  &.calendar-detail {
    bottom: 80px;
  }
  &.client-list {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 1rem;
    &.banner-displayed {
      margin-top: 6rem;
    }
    .add-client-button {
      position: relative !important;
      right: auto;
      margin: 0;
      @media screen and (max-width: 1100px) {
        width: 2.25rem;
      }
    }
    .lista-clienti-date-range {
      &.position-of-tuning-date-range {
        position: relative;
        right: auto;
        @media screen and (max-width: $BREAKPOINT_SMARTPHONE) {
          position: absolute;
          left: 0;
          padding-left: 1rem;
        }
      }
    }
    .switch-interface-btn {
      border-radius: 0;
      height: 2.25rem;
      min-height: 2.25rem;
      font-size: 12px;
      // position: absolute;
      right: 116px;
      @media (min-width: $BREAKPOINT_SMARTPHONE) {
        right: 410px;
      }
      @media (min-width: $BREAKPOINT_MD) {
        right: 488px;
      }
      &:hover {
        color: $accent;
        border-color: $accent;
        .tecma-icon path {
          fill: $accent;
        }
      }
      .tecma-icon {
        height: 1rem;
        min-height: 1rem;
      }
    }
    .screenshot-button {
      position: relative !important;
      right: auto;
    }
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    bottom: 45px;
    &.proposal-manager {
      bottom: 4px;
    }
    &.calendar-detail {
      bottom: 55px;
    }
    &.calendar {
      bottom: 60px;
    }
    &.client-list,
    &.quote-list,
    &.documenti-hc {
      bottom: 0;
    }
    &.account-manager {
      bottom: 70px;
    }
  }
}

.body-wrapper-open {
  position: relative;
  transition:
    margin 1s,
    padding 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-right: 16px;
    padding-bottom: 10px;
    margin-left: 216px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-bottom: 8px;
  }
}
.body-wrapper-close {
  position: relative;
  transition:
    margin 1s,
    padding 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-right: 16px;
    padding-bottom: 10px;
    margin-left: 72px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-bottom: 8px;
  }
}
.page-title {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 400;
  padding-top: 55px;
  padding-bottom: 24px;
  margin-left: 16px;
  color: $on-general-sub;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 2rem;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 2.5em;
  }
}
.documentId-chip-item {
  margin: 10px;
  font-weight: bold;
}

.file-uploader-container {
  width: fit-content;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  .uploaded-files > ol > li > div {
    display: flex;
    align-items: center;
  }
}

.quoteOrder {
  right: 280px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    right: unset !important;
    padding-left: 16px !important;
  }
}
.configQuote-button {
  position: absolute !important;
  top: 0;
  right: 145px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  color: #989898 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 36px;
    min-width: 36px !important;
    right: 66px;
  }
}
.configQuote-button:hover {
  color: $accent !important;
  background-color: white !important;
  border: 1px solid $accent !important;
}

.screen-invisible-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.screenshot-button {
  position: absolute !important;
  right: 14px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  color: $on-general !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: 36px !important;
    width: 36px !important;
  }
}
.screenshot-button:hover {
  color: $accent !important;
  background-color: white !important;
  border: 1px solid $accent !important;
}
.border-grid {
  @media (min-width: $BREAKPOINT_MD) {
    height: 377px;
  }
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding: 8px 16px;
  }
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-container-start {
  @extend .flex;
  justify-content: flex-start;
}
.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.bottom-card {
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.font-bold {
  font-family: 'Lato';
  font-weight: 700;
}

.font-regular {
  font-family: 'Lato';
  font-weight: 400;
}

.circle-in-table {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}

.dashboard-info {
  display: flex;
  @media (max-width: $BREAKPOINT_LG) {
    flex-flow: column;
  }
  margin-left: 16px;
  margin-right: 18px;
  margin-bottom: 16px;
  margin-top: 40px;
  width: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .dashboard-info-title {
    @extend .black;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      font-size: 16px;
    }
    + .small-font-14 {
      font-weight: 300;
    }
  }
  .dashboard-info-items {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (min-width: $BREAKPOINT_LG) {
      align-items: flex-end;
    }
    @media (max-width: $BREAKPOINT_LG) {
      justify-content: flex-start;
      padding-top: 24px;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding-top: 16px;
    }
  }
}

.anchor-link {
  color: $accent;
  text-decoration: none;
  border-bottom: 2px solid $accent;
}

.table-button {
  background-color: $accent !important;
  font-size: 12px !important;
  color: $on-accent !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 15px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  min-width: 100px;
  justify-content: space-evenly;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }
}

.table-open-modal-button {
  min-width: 35px !important;
  padding: 10px 10px 10px 10px !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 0px !important;
  background-color: white !important;
  margin-left: 6px !important;
  margin-bottom: 7px !important;
  color: #989898 !important;
  .MuiButton-label {
    height: 15px !important;
    width: 15px !important;
  }
}
.table-open-modal-button:hover {
  color: $accent !important;
  border: 1px solid $accent !important;
}
.table-open-modal-button-open {
  min-width: 35px !important;
  padding: 10px 10px 10px 10px !important;
  border: 1px solid $accent !important;
  border-radius: 0px !important;
  background-color: white !important;
  margin-left: 6px !important;
  margin-bottom: 7px !important;
  color: $accent !important;
  .MuiButton-label {
    height: 15px !important;
    width: 15px !important;
  }
}

.table-appartments-options {
  width: 141px !important;
  border: 1px solid #e8e9eb !important;
  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 8px !important;
    height: 40px !important;
  }
  .MuiMenuItem-root:hover {
    background-color: $accent !important;
    color: $on-accent !important;
  }
}

.custom-table-action {
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }
  .filter-button {
    background-color: $accent !important;
    font-size: 12px !important;
    color: $on-accent !important;
    text-transform: capitalize !important;
    margin-bottom: 7px !important;
    margin-left: 15px !important;
    padding: 7px 15px 7px 15px !important;
    height: 36px !important;
    width: 86px;
    justify-content: space-evenly;
    @media (max-width: $BREAKPOINT_SM) {
      padding: 7px !important;
      width: 36px !important;
      margin: 0px !important;
    }
    .tune-icon {
      width: 16px;
      height: 16px;
      transform: rotate(90deg);
    }
  }
}

.table-preventivi-action {
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -5px;
    right: 0;
    display: flex;
    align-items: center;
  }
}

.table-title {
  text-transform: capitalize;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

.editable-title {
  text-transform: capitalize;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 5px;
    left: 0;
  }
}

.table-clients-manager-options {
  width: 8.875rem !important;
  border: 1px solid #e8e9eb;
  left: -0.12rem !important;

  @media (max-width: '1100px') {
    width: 8rem !important;
    left: 0.7rem !important;
  }
  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 0.625rem !important;
    font-weight: 600 !important;
    padding-left: 0.5rem !important;
    height: 2.5rem !important;

    @media (max-width: '1100px') {
      padding-left: 0.25rem !important;
    }
  }
  .MuiMenuItem-root:hover {
    background-color: $accent !important;
    color: $on-accent !important;
  }
}

.circle-in-table-quotes {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
}

.circle-in-table-quotes-retry-payment {
  @extend .circle-in-table-quotes;
  background-color: #fc9358;
}

.circle-in-table-quotes-validate {
  @extend .circle-in-table-quotes;
  background-color: $booked;
}
.circle-in-table-quotes-counter-sign {
  @extend .circle-in-table-quotes;
  background-color: $accent;
}

.circle-in-table-quotes-confirmed {
  @extend .circle-in-table-quotes;
  background-color: #fdc132;
}

.circle-in-table-delete-prop {
  @extend .circle-in-table-quotes;
  background-color: #cd4a20;
}
.my-arrow-delete-proposal {
  position: relative;
  bottom: 20px;
  left: -20px;
}

.pdf-dropdown-menu {
  width: 120px !important;
  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 0px !important;
    height: 50px !important;
    justify-content: center;
  }
  .MuiMenuItem-root:hover {
    background-color: white !important;
  }
}

.page-back-button {
  background-color: white !important;
  color: #989898 !important;
  border: 1px solid #e8e9eb !important;
  text-transform: none !important;
  box-shadow: none !important;
  margin-right: 25px !important;
  height: 40px;

  .MuiButton-label {
    display: block;
    font-size: 14px;
  }
}

.editable-table-plus-button {
  background-color: $accent !important;
  font-size: 12px !important;
  color: $on-accent !important;
  text-transform: capitalize !important;

  width: 80px;
  height: 36px;
  position: relative;
  top: -5px;
  right: -12px;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -20px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: absolute;
    top: -30px;
    right: -10px;
    padding: 0 !important;
    width: 36px;
    min-width: unset !important;
    .MuiButton-label {
      width: fit-content;
    }
  }
}

.font-16 {
  font-size: 16px;
}

.MuiAutocomplete-endAdornment {
  .Mui-disabled {
    visibility: hidden;
  }
}

.MuiAutocomplete-popper {
  border: 1px solid #e3e5eb;
  .MuiAutocomplete-listbox {
    padding: 1rem;
    max-height: 12rem;
    overflow-y: auto;
    width: calc(100% - 2.5rem);
    &.custom-listbox {
      width: calc(100% - 0.5rem);
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
      position: relative;
      right: 1rem;
      padding-right: 1rem;
    }
    &::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 0.5rem;
      border: 0;
    }

    .MuiAutocomplete-option {
      font-size: 0.875rem;
      padding: 0.5rem;
      border-radius: 0.125rem;
      width: 100%;
      text-transform: none !important;
    }
  }
}

.MuiFormLabel-root.Mui-disabled {
  color: #989898;
  font-size: 14px;
  padding-bottom: 15px;
  display: flex;
  top: -5px;
}

.additional-label-readonly {
  color: #989898;
  font-size: 14px;
  display: flex;
  top: -5px;
}

.planimetry-icon {
  height: 100%;
  display: flex;
  align-items: center;
  width: 75px;
  justify-content: center;
  border-right: 1px solid #e8e9eb;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 67px;
    padding-top: 2px;
  }
}
.planimetry-text {
  padding-left: 23px;
  font-size: 16px;
}

.image-size {
  max-width: 100%;
  max-height: 600px;
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  height: 35px;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 7px;
  position: relative;
  right: 0px;
}

.zoom-button {
  border: 1px solid #1e201f !important;
  width: 35px;
}
.zoom-button:hover {
  background-color: transparent !important;
}

.image-zoom-container {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.image-size {
  max-width: 100%;
  max-height: 700px;
}

.modify-apartment-form-container {
  padding: 10px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 0;
    margin-top: -5px;
  }
  .input-wrapper-container {
    margin: 4px !important;
    height: 80px;
    .input-wrapper-input-component {
      padding-top: 7px;
    }
  }
}
.select-quote-list {
  padding: 8px;
  @media (max-width: 960px) {
    padding-inline: 16px;
  }
  .input-wrapper-container {
    margin: unset;
    .input-wrapper-input-component {
      margin-left: unset;
    }
  }
}
.status-apartment-tab {
  width: 100%;
  background-color: white;
  .status-apartment-tab-header {
    height: 80px;
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      height: 56px;
    }
    .status-text {
      display: flex;
      align-items: center;
      padding-left: 23px;
      font-size: 16px;
    }
  }
  .status-apartment-tab-content {
    padding-bottom: 25px;
    .status-apartment-legend {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      margin-right: 16px;
      .status-apartment-legend-item {
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-top: 5px;
        .status-apartment-legend-circle {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
        }
        .status-apartment-legend-label {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 600;
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 14px;
          }
        }
        .status-apartment-legend-light {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 200;
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 14px;
          }
        }
      }
    }
    .status-apartment-chip-container {
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        margin-top: 17px;
      }
      .status-apartment-chip-item {
        background-color: rgba(232, 233, 235, 0.38);
        height: 32px;
        margin-top: 25px;
        margin-left: 16px;
        border-radius: 18px;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          margin-top: 8px;
        }
        .status-apartment-chip-item-circle {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          display: inline-block;
          margin-left: 0px !important;
        }
        .status-apartment-chip-label {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

.padding-add-client {
  padding: 16px;
}

.gestione-privacy-checkbox {
  display: flex;
  padding-left: 16px;
  .input-wrapper-container {
    margin: 10px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: block;
    padding-left: 10px;
  }
  .MuiGrid-item {
    min-width: fit-content;
  }
  .MuiGrid-grid-md-4 {
    flex-basis: auto;
  }
  .input-wrapper-container {
    height: fit-content;
    .input-wrapper-input-component {
      padding-left: 0px !important;
      margin-left: 0px;
    }
  }
}
.empty-time .fc-time {
  display: none;
}
.MuiAccordionDetails-root {
  .input-wrapper-input-component {
    margin-left: 0px !important;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin-left: 0px !important;
    }
  }
}
.Mui-disabled::before {
  border-bottom: 1px solid #e8e9eb;
}
.grid-add-client-container {
  .input-wrapper-icon-container {
    height: 65px;
  }
}

.add-client-general-accordion-summary {
  height: 70px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 55px !important;
  }
  .border {
    border-radius: 1px;
  }
  .add-client-general-accordion-label {
    font-size: 14px;
    font-weight: 600;
    padding-left: 16px;
  }
}

.filter-list {
  height: 50px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 55px !important;
  }

  border: 1px solid #dcdcdc !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 6px 32px !important;
  gap: 300px !important;
  margin: 16px 32px !important;
}

.filter-list-title {
  /* width: 52px; */
  height: 24px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-self: revert !important;
  text-transform: capitalize;
  padding-left: 30px !important;
  color: #1e201f;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.filter-list-border {
  border: 1px solid #f0f0f0 !important;
  margin-top: -17px !important;
  margin-left: 32px !important;
  margin-right: 32px !important;
  &.loading {
    background: #f0f0f0;
  }
}

.no-custom-filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 30px;

  &__title {
    color: #000;
    font-family: Lato;
    font-weight: 700;
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
  }
  &__description {
    color: #000;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
    &__link {
      color: #7fbfb4;
      margin-left: 4px;
      font-family: Lato;
      font-weight: 700;
      font-size: 0.875rem;
      font-style: normal;
      line-height: normal;
      text-decoration-line: underline;
      color: $accent;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.filter-grid-wrapper {
  margin-left: 30px;
  margin-right: 16px;
  margin-top: 30px;
  &-loading {
    margin: 2rem auto;
  }
}

.radio-button-proposal-table-yes {
  padding-left: 8px !important;
  .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
  }
}

.circle-dotted {
  border: 2px dotted gray;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.radio-button-icon {
  height: 20px;
  width: 20px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 3px;
}
.radio-button-icon-disabled {
  height: 20px;
  width: 20px;
  border: 1px solid #989898;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.down-arrow {
  margin-left: 10px;
  margin-bottom: 2px;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.up-arrow {
  margin-left: 10px;
  margin-bottom: -5px;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.add-viewed-apartment-modal {
  background-color: white !important;
  width: 50%;
  display: flex;
  flex-flow: column;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
  }
  .add-viewed-apartment-modal-header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e9eb;
    .add-viewed-apartment-modal-title {
      color: #989898;
      padding-left: 32px;
      font-family: 'Lato';
      font-size: 18px;
    }
    .add-viewed-apartment-modal-close-button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      .close-button:hover {
        background-color: transparent;
      }
    }
  }
  .lateral-modal-scrollbar::-webkit-scrollbar {
    width: 16px;
  }
  .lateral-modal-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .lateral-modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.21);
    border-radius: 9px;
    background-clip: content-box;
    border-right: 2px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 3px solid transparent;
  }
  .lateral-modal-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.51);
  }
  .add-viewed-apartment-modal-elements {
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;

    .add-viewed-apartment-modal-selectors {
      max-width: 250px;
      padding-top: 16px;
      padding-left: 32px;
      label {
        font-weight: 700 !important;
      }
    }
    .add-item-modal-text-field {
      margin-left: 32px;
      margin-top: 16px;
      width: 100%;
      label {
        font-weight: 700 !important;
      }
    }
  }
  .filter-modal-elements {
    width: 100%;
    height: 72px;
    display: flex;
    .filter-container {
      margin: 16px;
      justify-content: space-between;
      align-items: center;
    }
    .filter {
      font-weight: bold;
      font-size: 14px;
      margin-left: 32px;
      margin-block: 16px;
    }

    .chip-tag-container {
      margin-left: 32px;
      height: auto;
      overflow-y: auto;
      padding-bottom: 90px;
    }
    .add-icon {
      color: $on-accent;
      background-color: $accent;
      border-radius: inherit;
      width: 16px;
      height: 16px;
    }
    .clear-icon {
      color: $accent;
      background-color: white;
      border-radius: inherit;
      width: 16px;
      height: 16px;
    }
  }
  .MuiAutocomplete-tag,
  .MuiAutocomplete-tagSizeSmall {
    margin: 0px 8px 5px 0px;
    max-width: calc(100% - 6px);
    min-width: 70px;
    background: #fff;
    border: 1px solid #e8e9eb;
    font-size: 12px;
    color: #989898;
    height: 24px;
    font-family: 'Lato';
    font-weight: 400;
  }
  .MuiChip-labelSmall {
    padding-right: 16px;
  }
  .add-viewed-apartment-modal-footer {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    justify-content: flex-end;
    border-top: 1px solid #e8e9eb;
    background-color: white;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      position: fixed;
      justify-content: space-between;
    }
    &-buttons {
      display: flex;
    }

    .reset {
      color: $accent !important;
      text-transform: capitalize;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      &:hover {
        background-color: transparent;
      }
      .cached-icon {
        padding-top: 7px;
        padding-right: 5px;

        transform-origin: 9px 19px;
        &:hover {
          rotate: 360deg;
          transition: all 2s;
        }
        width: 18px;
      }
    }

    .other-button-footer {
      display: flex;
      align-items: center;
      padding: 20px;
    }

    .cancel-footer-button {
      margin-top: 25px;
      margin-bottom: 25px;
      margin-right: 18px;
      width: 80px;
      text-transform: none;
      border: 1px solid #e8e9eb;
      background-color: white;
      color: #989898 !important;
      font-size: 12px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 136px;
        margin-right: 15px;
        margin-left: 15px;
        height: 44px;
      }
    }
    .cancel-footer-button :hover {
      background-color: white;
    }
    .save-footer-button {
      margin-top: 25px;
      margin-bottom: 25px;
      margin-right: 32px;
      width: 80px;
      text-transform: none;
      background-color: $accent;
      color: $on-accent;
      font-size: 12px;
      &[disabled] {
        opacity: 0.3;
        background-color: $accent;
        &:hover {
          opacity: 0.3;
          background-color: $accent;
        }
      }
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 136px;
        margin-right: 15px;
        height: 44px;
      }
    }
    .button-phone-save-class {
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 50px;
      }
    }
    .button-phone-cancel-class {
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 50px;
        margin-right: 10px !important;
        margin-left: 0px !important;
      }
    }
  }

  .filter-modal-footer {
    position: fixed;
    display: flex;
    bottom: 0;
    right: inherit;
    width: 50%;
    height: 90px;
    justify-content: flex-end;
    border-top: 1px solid #e8e9eb;
    background-color: white;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      position: fixed;
      justify-content: space-between;
      width: 100%;
    }
    .cancel-footer-button {
      margin-top: 25px;
      margin-bottom: 25px;
      margin-right: 18px;
      width: 80px;
      text-transform: none;
      border: 1px solid #e8e9eb;
      background-color: white;
      color: #989898 !important;
      font-size: 12px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 136px;
        margin-right: 15px;
        margin-left: 15px;
        height: 44px;
      }
    }
    .cancel-footer-button :hover {
      background-color: white;
    }
    .save-footer-button {
      margin-top: 25px;
      margin-bottom: 25px;
      margin-right: 32px;
      width: 80px;
      text-transform: none;
      background-color: $accent;
      color: $on-accent;
      font-size: 12px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 136px;
        margin-right: 15px;
        height: 44px;
      }
    }
  }
}

.language-option {
  font-size: 12px;
  font-weight: 600;
  > span {
    font-size: 11px;
    font-weight: 300;
  }
}

.add-viewed-apartment-modal-item {
  color: $on-general;
  font-weight: 600;
  padding-left: 5px !important;
  border: 0px !important;
  text-transform: capitalize;
}
.add-viewed-apartment-modal-item:hover {
  color: white;
  background-color: transparent !important;
}

.disabled-wrapped-input {
  color: #989898;
}

.radio-button-modify-client {
  .MuiSvgIcon-root {
    height: 29px;
    width: 29px;
  }
}

.modify-client-buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.delete-client-button {
  background-color: #f35959 !important;
  color: white !important;
  font-size: 12px;
  height: 36px !important;
  text-transform: none;
  min-width: 120px !important;
}
.delete-client-button:hover {
  // background-color: #f35959 !important;
}

.cancel-client-button {
  text-transform: none;
  border: 1px solid #e8e9eb !important;
  background-color: white;
  height: 36px !important;
  margin-right: 10px !important;
  min-width: 80px !important;
  font-size: 12px;
}

.cancel-client-button:hover {
  background-color: white;
}

.save-client-button {
  text-transform: none;
  background-color: $accent;
  color: $on-accent;
  min-width: 80px !important;
  font-size: 12px;
  height: 36px !important;
}
.save-client-button:hover {
  background-color: $accent !important;
}

.save-client-button.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.add-item-modal-switch {
  margin-left: 32px;
  margin-top: 50px;
}

// // AGGIUSTAMENTO TABELLE
.MuiTextField-root {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100% !important;
  }
}

.MuiToolbar-regular {
  @media (max-width: $BREAKPOINT_MD) {
    min-height: 80px !important;
  }
}

.MuiTableCell-footer {
  padding-top: 16px !important;
  @media (max-width: $BREAKPOINT_MD) {
    padding-top: 0 !important;
  }
}
.table-proposal-and-quote {
  padding: 15px;
  border: 1px solid #e8e9eb;
  .MuiTableCell-footer {
    @media (max-width: 320px) {
      position: relative;
      left: -30px;
    }
  }
}

//----------------------------------

.add-account-modal-elements {
  width: 100%;
  height: 72px;
  display: flex;
  .add-item-modal-text-field {
    margin-left: 32px;
    margin-top: 16px;
    width: 100%;
    label {
      font-weight: 700 !important;
    }
  }
  .add-item-modal-text-field-right {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 16px;
    width: 100%;
    label {
      font-weight: 700 !important;
    }
  }
}
.add-item-modal-selector {
  margin-left: 32px;
  margin-top: 32px;
  width: 43.3%;
  label {
    font-weight: 700 !important;
  }
}

.border-bottom-edit-row {
  border-bottom: 2px solid $accent !important;
  .MuiInput-input {
    font-weight: 700;
  }
  .MuiAutocomplete-root {
    min-width: 100px;
  }
  .MuiInputLabel-formControl {
    position: unset !important;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
  }
}

.circular-progress-in-filters {
  display: flex;
  justify-content: center;
  width: 100%;
}

.circular-progress-in-lateral-modal {
  width: 100px !important;
  height: 100px !important;
  position: relative;
  margin-left: 50%;
  margin-top: 50%;
  left: -50px;
  top: -50px;
  color: $accent !important;
}

.input-checkbox-container {
  .input-checkbox-main-container {
    display: flex;
    align-items: center;
    .input-checkbox-label {
      font-size: 14px;
      color: #989898;
      min-width: 100px;
    }
    .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
    }
  }
  .input-checkbox-error {
    color: #f44336 !important;
  }

  .input-checkbox-error-helper {
    margin-top: 0;
    font-size: 12px;
    color: #f44336;
    padding-left: 5px;
  }
}

.circular-progress-in-proposal-manager {
  color: $accent !important;
  height: 30px !important;
  width: 30px !important;
  position: relative;
  left: 0%;
}

.download-proposal-button {
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.download-proposal-button:hover {
  background-color: transparent !important;
}

.disabled-input {
  .MuiInputBase-root {
    margin-top: 10px !important;
  }
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom: 0px;
}

.edit-profile-icon {
  padding-right: 9px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding: 0px;
  }

  div {
    display: flex;
  }
}

.link-input {
  .MuiInput-input {
    text-overflow: ellipsis;
  }
}
.MuiInput-input {
  color: $on-general !important;
  font-weight: 400 !important;
}
.copy-link-button {
  height: 36px;
  text-transform: none;
  background-color: $accent;
  color: $on-accent;
  box-shadow: none;
  margin-left: 16px;
  margin-top: 10px;
  font-size: 12px;
  min-width: 85px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: relative;
    margin: 0px;
    right: -16px;
  }
}
.copy-link-button:hover {
  background-color: $accent;
  box-shadow: none;
}

.partecipate-link-button {
  height: 36px;
  text-transform: none;
  background-color: $accent;
  margin-left: 11px;
  color: $on-accent;
  box-shadow: none;
  margin-top: 10px;
  font-size: 12px;
  min-width: 115px;
  line-height: 15px;
  text-transform: capitalize;
}
.partecipate-link-button:hover {
  background-color: $accent;
  box-shadow: none;
}

.ok-calendar-button {
  background-color: $accent;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: $on-accent;
  font-size: 12px;
}
.ok-calendar-button:hover {
  background-color: $accent;
}
.cancel-calendar-button {
  width: 80px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #e8e9eb;
  color: #989898;
  font-size: 12px;
}
.cancel-calendar-button:hover {
  background-color: white;
}
.slot-event {
  background-color: #fff !important;
  border-radius: 0px !important;
  text-transform: none;
  font-family: 'Lato' !important;
  color: #989898;
}
.slot-event:hover {
  background-color: #fff !important;
}
.slot-event-active {
  color: $accent !important;
  border: 1px solid $accent !important;
  z-index: 2;
  font-weight: 700;
}
.slot-group {
  flex: 2;
  justify-content: center;
}

.hide-component {
  display: none;
}

.delete-margin {
  margin: 0 !important;
}
.client-manager-more-button {
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  background-color: white !important;
  text-transform: none !important;
  color: #989898 !important;
  font-size: 12px !important;
  border: 1px solid #e8e9eb !important;
  min-width: 142px !important;
  height: 37px;
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
}
.client-manager-more-button-disabled {
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  background-color: rgba(232, 233, 235, 0.38) !important;
  text-transform: none !important;
  color: #cacbd3 !important;
  font-size: 12px !important;
  border: 1px solid #e8e9eb !important;
  min-width: 142px !important;
  height: 37px;
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
}

.validate-apartment-button {
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  background-color: white !important;
  text-transform: none !important;
  color: $accent !important;
  font-size: 12px !important;
  border: 1px solid $accent !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px;
  width: 142px;
  svg path {
    fill: $accent;
  }
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-right: 10px !important;
    width: auto;
  }
}

.export-apartment-button {
  background-color: $accent !important;
  font-size: 12px !important;
  color: $on-accent !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }
}
.export-configuration-button {
  background-color: $accent !important;
  font-size: 12px !important;
  color: $on-accent !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
}
.import-apartment-button {
  background-color: $accent !important;
  font-size: 12px !important;
  color: $on-accent !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: '1100px') {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
    .edit-event-button {
      margin-top: 30px;
      background-color: $accent !important;
      color: $on-accent !important;
      box-shadow: none;
      font-size: 12px;
      text-transform: none;
      height: 36px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        max-width: 36px;
        padding: 0px;
        min-width: 36px;
        margin-top: 0px;
      }
    }

    .edit-event-button:hover {
      background-color: $accent;
      color: $on-accent;
      box-shadow: none;
    }
    .disabled-edit-event-button {
      margin-top: 30px;
      background-color: #e0e0e0 !important;
      color: white !important;
      box-shadow: none;
      font-size: 12px;
      text-transform: none;
      height: 36px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        max-width: 36px;
        padding: 0px;
        min-width: 36px;
        margin-top: 0px;
      }
    }
    .disabled-edit-event-button:hover {
      background-color: #e0e0e0;
      color: white;
      box-shadow: none;
    }
    .modify-event-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      .delete-event-button {
        background-color: #f35959 !important;
        color: white !important;
        font-size: 12px;
        height: 36px !important;
        text-transform: none;
        min-width: 80px !important;
        box-shadow: none;
        margin-right: 10px;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
          margin-right: 5px;
        }
      }

      .delete-event-button:hover {
        background-color: #f35959 !important;
        box-shadow: none;
      }

      .delete-reassign-event-button {
        background-color: #f35959 !important;
        color: white !important;
        font-size: 11px;
        height: 36px !important;
        text-transform: none;
        box-shadow: none;
        margin-right: 10px;
        width: 100px;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          width: 80px !important;
          margin-right: 5px;
          font-size: 10px;
        }
      }
      .delete-reassign-event-button:hover {
        background-color: #f35959 !important;
        box-shadow: none;
      }

      .cancel-event-button {
        text-transform: none;
        border: 1px solid #e8e9eb !important;
        background-color: white !important;
        color: #989898;
        height: 36px !important;
        margin-right: 10px !important;
        min-width: 80px !important;
        font-size: 12px;
        box-shadow: none;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
          margin-right: 5px !important;
        }
      }
      .cancel-event-button:hover {
        background-color: white !important;
        box-shadow: none;
      }
      .save-event-button {
        text-transform: none;
        background-color: $accent !important;
        color: $on-accent !important;
        min-width: 80px !important;
        font-size: 12px;
        height: 36px !important;
        box-shadow: none;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
        }
      }
      .save-event-button:hover {
        background-color: $accent !important;
        box-shadow: none;
      }
    }
  }
}

.menu-home-button {
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  background: $accent !important;
  margin-right: 10px;
  color: $on-accent !important;
}

@media (max-width: 480px) {
  .menu-home-button {
    width: 32px;
    height: 32px;
    margin-right: 0;
    min-width: none !important;
  }
}

.home-button {
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  background: $accent !important;
  margin-right: 10px;
  color: $on-accent !important;
}

.add-client-button {
  &:not(.add-client-button-v2) {
    position: absolute !important;
    top: 0;
    right: 355px;
    text-transform: none !important;
    font-size: 12px !important;
    border-radius: 0px;
    width: 120px;
    height: 35px;
    background: $accent !important;
    margin-right: 10px;
    color: $on-accent !important;
    z-index: 1;
    @media (max-width: $BREAKPOINT_MD) {
      width: 36px;
      margin-right: 0px;
      right: 360px;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      right: 66px;
    }
  }
}

// .add-client-button-v2 {
//   position: relative !important;
//   right: auto;
//   width: auto;
//   height: auto;
// }

.calendar-settings-button {
  position: absolute !important;
  top: 0;
  right: 140px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 150px;
  height: 35px;
  color: #989898 !important;
  margin-right: 10px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 36px;
    min-width: 36px;
    margin-right: 0px;
    right: 66px;
  }
}
.calendar-settings-button:hover {
  color: $accent !important;
  background-color: $on-accent !important;
  border: 1px solid $accent !important;
}

.calendar-settings-accordions-grid {
  .calendar-settings-accordion {
    width: 100%;
    height: 40px;
    padding: 0px;
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px !important;
    }
    .MuiIconButton-edgeEnd {
      margin-right: 0px !important;
      padding: 0px !important;
    }
  }
  .MuiAccordion-root:before {
    background-color: transparent;
  }
  .calendar-settings-accordion-details {
    border-top: 0px !important;
    padding: 16px 0px 16px 0px !important;
  }
  .calendar-settings-accordion-details-out-office {
    border-top: 0px !important;
    padding: 0px !important;
  }
}
.calendar-colors-paper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 175px;
  background-color: white !important;
  border: 1px solid #e8e9eb;
  margin-top: 5px;
}

.color-text-field-selector {
  .MuiInputBase-input {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
    margin-bottom: 6px;
    margin-left: 2px;
  }
  .MuiFormLabel-root {
    font-size: 10px !important;
    top: -25px !important;
  }
  .MuiFormLabel-root.Mui-focused {
    top: -2px !important;
    font-size: 13px !important;
  }
}

.cross-chip-calendar-settings {
  position: relative;
  width: 8px;
  height: 8px;
}
.cross-chip-calendar-settings::before,
.cross-chip-calendar-settings::after {
  position: absolute;
  top: -1px;
  left: -1px;
  content: ' ';
  height: 12px;
  width: 1px;
  background-color: #989898;
}
.cross-chip-calendar-settings::before {
  transform: rotate(45deg);
}
.cross-chip-calendar-settings::after {
  transform: rotate(-45deg);
}
.calendar-settings-out-office {
  .input-wrapper-container {
    margin: 16px 16px 16px 0px !important;
    .input-wrapper-icon-container {
      padding-left: 0px !important;
      padding-right: 9px !important;
      width: 30px;
    }
    .input-wrapper-input-component {
      margin-left: 16px !important;
    }
  }
  .checkbox-calendar-settings {
    position: relative;
    left: -16px;
  }
}

.calendar-settings-repetOn {
  display: contents;

  .input-wrapper-container {
    margin: 0px !important;
    height: fit-content;
    min-width: fit-content;
    .input-checkbox-label {
      min-width: 80px;
    }
  }
  .MuiGrid-item {
    min-width: fit-content;
  }
  .MuiGrid-grid-md-2 {
    flex-basis: auto;
  }
}

.date-range-calendar-out-of-office {
  position: absolute;
  right: -2px;
  top: 140px;
  z-index: 1800;
  background-color: white !important;
  border: 1px solid #dddbda;
  @media (max-width: $BREAKPOINT_MD) {
    top: 240px;
    right: -10px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    right: -10px;
    top: 220px;
  }
  .customPrevButtonClassName i {
    border: solid black !important;
    border-width: 0 1px 1px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(135deg) !important;
    -webkit-transform: rotate(135deg) !important;
  }

  .customNextButtonClassName i {
    border: solid black !important;
    border-width: 0 1px 1px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(-45deg) !important;
  }
  .rdrMonthAndYearWrapper {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    .rdrNextPrevButton {
      background-color: transparent;
    }
  }
  .rdrMonth {
    padding-bottom: 8px;
    .rdrMonthName {
      display: none;
    }
    .rdrWeekDays {
      justify-content: center;
      .rdrWeekDay {
        color: $on-general;
        font-size: 12px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        padding-right: 4px;
      }
    }
  }
  .rdrDay {
    height: 40px;
  }
  .rdrDayNumber {
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    span {
      height: 100%;
      width: 100%;
    }
    width: 100%;
    top: 0px;
  }
  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left-width: 0px;
  }
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0px;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    //eliminazione bordo quando vai sopra
    border: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
    border-radius: 0px;
    top: 0px;
    bottom: 0px;
  }
  .rdrDayHovered {
    background-color: rgba(107, 193, 180, 0.1);
    top: 0px;
    bottom: 0px;
  }
  .rdrDayPassive {
    visibility: hidden;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: $accent;
    border: 0px;
    border-radius: 0px;
    height: 100%;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .rdrInRange {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 0px;
    border-radius: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
  }
  .rdrInRange ~ .rdrDayNumber span {
    //seleziona ogni rdrDayNumber che viene preceduto da un rdrInRange
    color: black !important;
  }
  .rdrDayToday .rdrDayNumber span {
    color: #009688;
    font-weight: 700;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: transparent !important;
  }
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after {
    background: transparent;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    left: 0px;
  }
}

.out-of-office-events {
  width: 100%;
  height: 70px;
  margin-bottom: 8px;
  border: 1px solid #dddbda;
  font-size: 14px;
}

.recurring-events {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  border: 1px solid #dddbda;
  font-size: 14px;
}

.radio-gray {
  .MuiIconButton-label {
    color: #989898;
  }
}

.center-radio {
  margin: 0px !important;
}

.radio-left {
  position: relative;
  left: -2px;
}
.confirmed-validation-menu {
  background-color: white !important;
  margin-top: 19px;
  margin-left: -5px;
  border: 0px !important;
  .confirmed-validation-item {
    width: 155px !important;
    font-size: 14px;
    border: 0px;
    padding-left: 10px !important;
    height: 30px;
    cursor: pointer;
  }
  .confirmed-delete-proposal-item {
    width: 155px !important;
    font-size: 14px;
    border: 0px;
    padding-left: 10px !important;
    height: 30px;
    cursor: pointer;
  }
}

.deleting-validation-menu {
  background-color: white !important;
  margin-top: 29px;
  margin-left: -10px;
  border: 0px !important;
  .confirmed-validation-item {
    width: 166px !important;
    font-size: 14px;
    border: 0px;
    padding-left: 10px !important;
    height: 30px;
    cursor: pointer;
  }
  .confirmed-delete-proposal-item {
    width: 166px !important;
    font-size: 14px;
    border: 0px;
    padding-left: 10px !important;
    height: 38px;
    cursor: pointer;
  }
}

.delete-recurring-event-button {
  height: fit-content;
  min-width: fit-content;
  padding: 0px;
}
.delete-recurring-event-button:hover {
  background-color: transparent;
}
.plus-button-add-item-modal {
  min-width: 16px !important;
  margin-left: 16px !important;
}
.plus-button-add-item-modal:hover {
  background-color: white !important;
}

.delete-user-info-manager {
  padding: 0px !important;
  min-width: fit-content !important;
  margin-left: 10px !important;
  color: #cccccc !important;
}
.delete-user-info-manager:hover {
  background-color: white !important;
  color: #f35959 !important;
}

.to-left-15px {
  position: relative;
  left: -15px;
}

.calendar-range-lista-preventivi {
  .date-range-calendar {
    @media (max-width: $BREAKPOINT_MD) {
      position: absolute;
      top: 140%;
      right: -266px;
    }
    @media (max-width: 740px) {
      position: absolute;
      top: 140%;
      right: -266px;
    }
  }
}

.calendar-range-lista-proposte {
  .date-range-calendar {
    @media (max-width: $BREAKPOINT_MD) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
    @media (max-width: 740px) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
  }
}

// .lista-clienti-date-range {
//   .date-range-calendar {
//     @media (max-width: $BREAKPOINT_MD) {
//       position: absolute;
//       top: 140%;
//       right: -133px;
//     }
//     @media (max-width: 740px) {
//       position: absolute;
//       top: 140%;
//       right: -133px;
//     }
//   }
// }

.slider,
.slider:before,
.slider:after {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 90%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  // repeat: no-repeat;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.SliderButton {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e201f;
  background-position: 45%;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
  top: 50%;
  position: absolute;
  z-index: 3;
}

.nextArrow {
  right: 10px;
  transform: rotate(180deg);
}

.prevArrow {
  left: 10px;
}

.image-magnifier-in-slider {
  .image-size {
    max-height: 575px;
  }
}
.share-planimetry-button {
  position: absolute;
  right: 120px;
  padding: 0px;
  @media (max-width: 400px) {
    right: 90px;
  }
}
.add-planimetry-button {
  position: absolute;
  right: 60px;
  padding: 0px;
  @media (max-width: 400px) {
    right: 40px;
  }
  .file-uploader-container {
    line-height: 0.75;
  }
}
.add-planimetry-button:hover,
.share-planimetry-button:hover {
  background-color: white !important;
}
.circular-progress-in-plan-apartment {
  width: 100px !important;
  height: 100px !important;
  margin-top: 25px;
  margin-bottom: 25px;
  color: $accent !important;
}

.border-right-pie-chart {
  border-right: 1px solid #cacbd3;
  @media (max-width: $BREAKPOINT_LG) {
    border-right: none !important;
    border-bottom: 1px solid #cacbd3;
  }
}

.interesse {
  @extend .color-white;
  background-color: $INTERESSE !important;
}

.disabled_web_text {
  margin-right: 10px;
  color: #989898;
  font-style: italic;
  margin-left: 4px;
  position: relative;
}

.disabled_web_text::before {
  content: '';
  position: absolute;
  left: -8px;
  height: 32px;
  top: -6px;
  width: 1px;
  background: #e8e9eb;
}

.text_extra_info {
  color: #989898;
  display: none;
  font-style: italic;
  margin-top: 5px;
}

.extrainfospace label + .MuiInput-formControl {
  margin-top: 25px;
}

.MuiInputLabel-shrink .text_extra_info {
  display: block;
}

/* Update UI import error modal */
.importErrorModal {
  height: 100%;
}

.importErrorModal .MuiGrid-item > li {
  list-style: none;
  padding-left: 3px;
}
.importErrorModal .MuiGrid-item {
  margin-left: 13px !important;
}

.importErrorModal .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column {
  width: auto;
}

.dashboard-container {
  .tab {
    position: relative;
    z-index: 2;
  }
  .item {
    position: relative;
    z-index: 0;
    height: fit-content;
    background: rgba(232, 233, 235, 0.38);
    border: 1px solid #e8e9eb;
  }
}

.dashboard-container-2 {
  padding: 16px;
  .container {
    border: 1px solid #e8e9eb;
    padding-top: 16px;
    padding-bottom: 6px;
    padding-inline: 10px;
    @media (max-width: $BREAKPOINT_SM) {
      padding-inline: unset;
    }
  }
  .title {
    margin-bottom: 25px;
    margin-inline: 5px;
    display: flex;
    justify-content: space-between;
  }
  .sx {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    @media (max-width: $BREAKPOINT_SM) {
      flex-direction: row;
      margin-bottom: 15px;
    }
    .number-scheduled-appointments {
      font-family: 'Lato';
      font-size: 48px;
      font-weight: 700;
      color: $graph-third-color;
    }
    .perc {
      font-weight: bold;
    }
  }
  .dx {
    border-left: 1px solid #cacbd3;
    padding-inline: 10px;
    @media (max-width: $BREAKPOINT_SM) {
      border-left: none;
      padding-inline: unset;
      border-top: 1px solid #cacbd3;
      padding-top: 15px;
    }
  }
}

.number-appointments {
  font-size: 16px;
  font-weight: bold;
  @media (max-width: $BREAKPOINT_SM) {
    font-size: 12px;
  }
}

.label {
  font-size: 14px;
  @media (max-width: $BREAKPOINT_SM) {
    font-size: 12px;
  }
}
.importErrorModal {
  height: 100%;
}

.uploadBulkModal {
  height: unset;
  align-self: flex-start;
}

.small-button-loader {
  margin-left: 4px;
  margin-bottom: 7px;
  max-width: 142px;
  max-height: 37px;
}
.align-text-left {
  text-align: left !important;
}
.appartmentTabs {
  width: 150px !important;
  @media (max-width: $BREAKPOINT_MD) {
    width: unset !important;
  }
}

.info-button {
  justify-content: flex-end !important;
  min-width: 36px !important;
}

.colonna-lista {
  width: 100px;
  display: flex;
  font-size: 12px;
}

.alert-doc-contrattuale {
  padding: 16px;
  span {
    font-size: 13px;
    font-weight: 600;
    color: #f35959;
  }
}

.alert-my-home-pro-disabled {
  padding-bottom: 8px;
  padding-top: 8px;
  span {
    font-size: 13px;
    font-weight: 600;
    color: #f35959;
  }
}

.dot-myhome {
  height: 15px;
  min-width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.dot-myhome-list {
  height: 15px;
  min-width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
  margin-right: 6px;
}

.opacity {
  opacity: 0.3;
}

.opacity-cancelled-proposal {
  opacity: 0.3;
  position: relative;
  left: 32px;
}

.notes-action-input {
  .MuiInputBase-root {
    display: flex;
    flex-flow: column;
    justify-content: start;
    font-size: 16px !important;
    .MuiInputBase-inputMultiline {
      overflow-y: auto !important;
    }
  }
  label {
    position: relative;
    left: -15px;
    top: -15px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: 900;
  }
  fieldset {
    border-radius: 0px !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $accent !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $accent !important;
  }
  legend {
    display: none;
  }
  textarea::placeholder {
    font-style: italic;
  }
  textarea {
    font-size: 16px !important;
  }
  .MuiOutlinedInput-multiline {
    padding: 15px;
    height: 260px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin-bottom: 91px;
    }
  }
}
.MuiDrawer-paperAnchorRight::-webkit-scrollbar {
  width: 8px !important;
}

.MuiDrawer-paperAnchorRight::-webkit-scrollbar-track {
  border-left: 0px !important;
  background-color: #e3e5eb;
}

.MuiDrawer-paperAnchorRight::-webkit-scrollbar-thumb {
  background-color: #ccd1d3;
  border-radius: 0px !important;
  border-right: 4px solid transparent;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.underline-text {
  text-decoration: underline;
}

.always-visible {
  visibility: visible;
}

.upload-proposal-button {
  text-transform: none !important;
  color: #989898 !important;
  font-size: 12px !important;
  border: 1px solid #989898 !important;
  min-width: 130px !important;
  height: 35px;
  .icon-upload-proposal {
    position: relative;
    top: 2px;
    left: -5px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.container-input-payment-code {
  margin-block: 20px;
}

.recommendation-payment-code {
  font-size: 12px;
}

.payment-code-field {
  width: 300px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 200px;
  }
}

.modal-payment-code-title {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 18px;
  }
}

.radio-group-convalida-proposta {
  position: relative;
  left: 25px;
  min-width: 190px;
}

.convalida-proposta {
  position: relative;
  left: 32px;
}

.valida-proposta {
  position: relative;
  left: 30px;
}

.info-tag-title {
  color: rgba(0, 0, 0, 0.38);
  font-family: 'Lato';
  font-size: 14px;
  margin-left: 16px;
  font-weight: 700;
}

.expand-more-icon {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-top: 3px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 20px;
    height: 20px;
    margin-left: 0px;
    margin-top: 2px;
  }
}

.expand-more-icon-rotate {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-top: 3px;
  transform: rotate(180deg);
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 20px;
    height: 20px;
    margin-left: 0px;
    margin-top: 2px;
  }
}

.hc-icons {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.hc-icons:hover {
  opacity: 0.2;
}
.hc-icons-tooltip {
  background: rgba(37, 37, 37, 0.9) !important;
  border-radius: 4px !important;
  padding: 8px;
  color: white;
  font-size: 13px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  min-height: 30px;
  margin: 0px !important;
}

.share-autocomplete {
  border: 1px solid #e3e5eb;
  margin-top: 0.5rem;
  padding: 1rem;
  left: 1rem;
  right: 1rem;
  width: auto !important;
  @media screen and (min-width: 960px) {
    right: unset;
    max-width: 80%;
  }
  &-paper {
    margin: 0;
  }
  &-listbox {
    padding: 0;
  }
  &-option {
    word-break: break-all;
    padding: 0.5rem !important;
    text-transform: none;
  }
  &-adornment {
    position: relative;
    display: flex;
  }
  &-inputRoot.MuiInputBase-adornedEnd {
    padding-right: 0;
  }
}

.crm-input {
  .crm-input-icon {
    &,
    & > div {
      display: flex;
    }
  }
}
.MuiPaper-root {
  border-color: $on-general-border !important;
  .MuiPaper-elevation2.MuiPaper-rounded {
    color: $on-general !important;
  }
}
.MuiTab-root.MuiButtonBase-root {
  border-color: $on-general-border !important;
}
.MuiTabs-root {
  .MuiTab-textColorInherit {
    color: $on-general;
    &.Mui-selected {
      background-color: $canvas;
    }
  }
}

.MuiTableCell-root.MuiTableCell-head {
  background-color: $rowColor !important;
  color: $on-general;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  padding-left: 18px !important;
}

.followup-switch.MuiSwitch-root {
  padding: 0;
  .MuiSwitch-switchBase,
  .MuiSwitch-switchBase.Mui-checked {
    top: 50% !important;
    transform: translate(0%, -50%);
    padding: 0;
  }
  .MuiSwitch-switchBase.Mui-checked {
    left: 100% !important;
    transform: translate(-50%, -50%);
  }
}
.align-end {
  align-items: flex-end;
}
